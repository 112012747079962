@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap");

body {
  font-family: "Mukta", sans-serif;
  font-size: 18px;
}
.flip-card {
  background-color: transparent;
  width: 300px;
  height: 200px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  animation: flipAnimation 5s infinite 5s; /* Apply the flip animation */
  transform-style: preserve-3d;
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  /* height: 100%; */
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: #bbb;
  color: black;
}

/* Style the back side */
.flip-card-back {
  color: white;
  transform: rotateY(180deg);
}

/* Apply the animation */
.flipped .flip-card-inner {
  animation: flipAnimation 10s infinite;
}

/* Set animation duration for each side */
.flipped .flip-card-inner {
  animation-duration: 5s; /* Show each side for 5 seconds */
}

/* Define the flip animation */
@keyframes flipAnimation {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

@media (max-width: 768px) {
  .flip-card-inner {
    animation: none; /* Disable the animation for mobile devices */
  }

  .flip-card-front,
  .flip-card-back {
    position: relative;
    width: 100%;
    /* height: 100%; */
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }

  .flip-card-back {
    position: relative;
    z-index: 9999;
    transform: none;
  }
}

@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.animate-scaleimage {
  animation: scaleAnimation 3s infinite; /* Adjust the duration (2s) and iteration count (infinite) as needed */
}
